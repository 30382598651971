import { api } from "../..";

const invoice_url = (root, filter, page) => {
  var url = root;

  if (filter.value !== undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=invoice_number:like;facility_invoice_number:like;pre_auth_code:like;dispatch_number:like;patient.first_name:like;patient.last_name:like;patient.middle_name:like;patient.patient_number:like;&search=" +
      filter.value.search_query;

  if (page) url = url + "&page=" + page;

  if (filter.value !== undefined && filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;

  if (filter.value !== undefined && filter.value.patient_id)
    url = url + "&patient_id=" + filter.value.patient_id;

  if (filter.value !== undefined && filter.value.doctor_id)
    url = url + "&doctor_id=" + filter.value.doctor_id;

  if (filter.value !== undefined && filter.value.employer_id)
    url = url + "&employer_id=" + filter.value.employer_id;

  if (filter.value !== undefined && filter.value.insurance_id)
    url = url + "&insurance_id=" + filter.value.insurance_id;

  if (filter.value !== undefined && filter.value.hospital_id)
    url = url + "&hospital_id=" + filter.value.hospital_id;

  if (filter.value !== undefined && filter.value.appointment_type)
    url = url + "&appointment_type=" + filter.value.appointment_type;

  if (filter.value !== undefined && filter.value.has_consumables)
    url = url + "&has_consumables=" + filter.value.has_consumables;

  if (filter.value !== undefined && filter.value.has_balance)
    url = url + "&has_balance=" + filter.value.has_balance;

  if (filter.value !== undefined && filter.value.payment_status)
    url = url + "&payment_status=" + filter.value.payment_status;

  if (filter.value !== undefined && filter.value.report_format)
    url = url + "&report_format=" + filter.value.report_format;

  url = url.replace("invoices?&", "invoices?");

  return url;
};
export const fetchConsumables = async (filter, page) => {
  return await api
    .get(invoice_url("invoices?orderBy=id&sortedBy=desc", filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const fetchInvoices = async (filter, page) => {
  return await api
    .get(invoice_url("invoices?orderBy=id&sortedBy=desc", filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const createInvoice = async (postBody) => {
  return await api
    .post("invoices", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const downloadGeneralInvoicesExcel = async (filter) => {
  return await api
    .get(
      payment_url(
        "general/report/download/excel?orderBy=id&sortedBy=desc",
        filter,
        1
      ),
      {
        responseType: "blob",
      }
    )
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const downloadInvoicesExcel = async (filter) => {
  return await api
    .get(
      invoice_url(
        "invoices/download/excel?orderBy=id&sortedBy=desc",
        filter,
        1
      ),
      {
        responseType: "blob",
      }
    )
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const downloadInvoicesPdf = async (filter) => {
  return await api
    .get(
      invoice_url("invoices/download/pdf?orderBy=id&sortedBy=desc", filter, 1),
      {
        responseType: "blob",
      }
    )
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
const payment_url = (root, filter, page) => {
  var url = root;

  if (filter.value !== undefined && filter.value.search_query)
    url = url + "&search=" + filter.value.search_query;

  if (page) url = url + "&page=" + page;

  if (filter.value !== undefined && filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;

  if (filter.value !== undefined && filter.value.patient_id)
    url = url + "&patient_id=" + filter.value.patient_id;

  if (filter.value !== undefined && filter.value.doctor_id)
    url = url + "&doctor_id=" + filter.value.doctor_id;

  if (filter.value !== undefined && filter.value.employer_id)
    url = url + "&employer_id=" + filter.value.employer_id;

  if (filter.value !== undefined && filter.value.insurance_id)
    url = url + "&insurance_id=" + filter.value.insurance_id;

  if (filter.value !== undefined && filter.value.hospital_id)
    url = url + "&hospital_id=" + filter.value.hospital_id;

  if (filter.value !== undefined && filter.value.payment_mode_id)
    url = url + "&payment_mode_id=" + filter.value.payment_mode_id;

  if (filter.value !== undefined && filter.value.appointment_type)
    url = url + "&appointment_type=" + filter.value.appointment_type;

  if (filter.value !== undefined && filter.value.has_consumables)
    url = url + "&has_consumables=" + filter.value.has_consumables;

  url = url.replace("invoices?&", "invoices?");

  return url;
};

export const downloadConsumablesExcel = async (filter) => {
  return await api
    .get(
      invoice_url(
        "invoices/consumables/download/excel?orderBy=id&sortedBy=desc",
        filter,
        1
      ),
      {
        responseType: "blob",
      }
    )
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const downloadConsumablesPdf = async (filter) => {
  return await api
    .get(
      invoice_url(
        "invoices/consumables/download/pdf?orderBy=id&sortedBy=desc",
        filter,
        1
      ),
      {
        responseType: "blob",
      }
    )
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const updateInvoice = async (body) => {
  return await api
    .patch("invoices/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const fetchInvoice = async (id) => {
  return await api
    .get(`invoices/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const deleteInvoice = async (id) => {
  return await api
    .delete("invoices/" + id)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const deleteInvoices = async (body) => {
  return await api
    .post("invoices/delete", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const downloadPaymentsPdf = async (filter) => {
  return await api
    .get(
      payment_url("payments/download/pdf?orderBy=id&sortedBy=desc", filter, 1),
      {
        responseType: "blob",
      }
    )
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const downloadPaymentsExcel = async (filter) => {
  return await api
    .get(
      payment_url(
        "payments/download/excel?orderBy=id&sortedBy=desc",
        filter,
        1
      ),
      {
        responseType: "blob",
      }
    )
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const fetchPayments = async (filter, page) => {
  return await api
    .get(payment_url("payments?orderBy=id&sortedBy=desc", filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const createPayment = async (postBody) => {
  return await api
    .post("payments", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const initiatePayment = async (postBody) => {
  return await api
    .post("stk-push", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const updatePayment = async (body) => {
  return await api
    .patch("payments/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const fetchPayment = async (id) => {
  return await api
    .get(`payments/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const deletePayment = async (id) => {
  return await api
    .delete("payments/" + id)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const deletePayments = async (body) => {
  return await api
    .post("payments/delete", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const createInvoiceStatus = async (postBody) => {
  return await api
    .post("invoice/status", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const createBulkInvoiceStatus = async (postBody) => {
  return await api
    .post("invoice/status/bulk/update", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};

export const createPaymentStatus = async (postBody) => {
  return await api
    .post("payment/status", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
export const createBulkPaymentStatus = async (postBody) => {
  return await api
    .post("payment/status/bulk/update", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
};
