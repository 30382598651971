import { api } from "../..";
export const fetchStatistics = async () => {
  let response;
  response = await api
    .get(`summary/statistics`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchMonthlyAppointments = async () => {
  let response;
  response = await api
    .get(`summary/monthly/appointments`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchDoctorsAppointments = async () => {
  let response;
  response = await api
    .get(`summary/doctors/appointments`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchPaymentSummary = async () => {
  let response;
  response = await api
    .get(`summary/payments`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchDebtors = async () => {
  let response;
  response = await api
    .get(`summary/debtors`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchPatientStatement = async (id) => {
  let response;
  response = await api
    .get(`patient/statement/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};

export const downloadPatientStatementPdf = async (id) => {
  let response;
  response = await api
    .get(`patient/statement/pdf/${id}`, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchAgingSummary = async () => {
  let response;
  response = await api
    .get(`summary/aging-debts`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const downloadAgingSummaryPdf = async (filter, page) => {
  var url = "summary/aging-debts/pdf?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("pdf?&", "pdf?");

  let response;
  response = await api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const downloadAgingSummaryExcel = async (filter, page) => {
  var url = "summary/aging-debts/excel?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("excel?&", "excel?");
  let response;
  response = await api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchInventoryReport = async () => {
  let response;
  response = await api
    .get(`stock/report`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const downloadInventoryReportPdf = async (filter, page) => {
  var url = "stock/report/pdf?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("pdf?&", "pdf?");

  let response;
  response = await api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const downloadInventoryReportExcel = async (filter, page) => {
  var url = "stock/report/excel?orderBy=id&sortedBy=desc";
  if (filter.value.date_range)
    url = url + "&date_range=" + filter.value.date_range;
  if (page) url = url + "&page=" + page;
  url = url.replace("excel?&", "excel?");
  let response;
  response = await api
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
