import {api} from "../../index";

const referral_url = (root, filter, page) => {
    var url = root;

    if (filter.value !== undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=patient.first_name:like;patient.last_name:like;patient.middle_name:like;patient.patient_number:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value !== undefined && filter.value.date_range)
        url = url + "&date_range=" + filter.value.date_range;

    if (filter.value !== undefined && filter.value.patient_id)
        url = url + "&patient_id=" + filter.value.patient_id;

    if (filter.value !== undefined && filter.value.doctor_id)
        url = url + "&issued_by=" + filter.value.doctor_id;

    url = url.replace("leaves?&", "leaves?");

    return url;
};

export const fetchLeaves = async (filter, page) => {
     let response;
  response = await api
        .get(referral_url("leaves?orderBy=id&sortedBy=desc", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
export const createLeave = async (postBody) => {
     let response;
  response = await api
        .post("leaves", postBody)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateLeave = async (body) => {
     let response;
  response = await api
        .patch("leaves/" + body.id, body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const updateLeaveStatus = async (body) => {
     let response;
  response = await api
        .post("update/leave/status",  body)
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};

export const fetchLeave = async (id) => {
     let response;
  response = await api
        .get(`leaves/${id}`)
        .then((response) => response.data)
        .catch((e) => e.response.data);

    return response;
};

export const downloadLeavePdf = async (id) => {
     let response;
  response = await api
        .get(`download/leave/sheet/${id}`,
            {
                responseType: "blob",
            }
        )
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
