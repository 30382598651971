<script setup>
import Loader from "@/views/components/Loader.vue";
import { ref } from "vue";
import { fetchAgingSummary,downloadAgingSummaryPdf,downloadAgingSummaryExcel } from "@/api/services/reports";
const filter_params = ref({});
import { ElMessage } from "element-plus";
const tableData = ref([]);
const isLoading = ref(false);
const fetchData = async (filter, page) => {
  isLoading.value = true;
  let entity_params = {};
  try {
    if (filter === undefined) {
      entity_params = ref({});
    } else {
      entity_params = filter;
    }
    tableData.value.splice(0);
    const response = await fetchAgingSummary(entity_params, page);
    tableData.value = response.report?.map((item) => item);
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};
const downloadExcel = async () => {
  try {
    ElMessage({
      type: "info",
      message: "Your report will be downloading shortly. Please wait...",
    });
    await downloadAgingSummaryExcel(filter_params)
      .then((Response) => {
        let fileURL = window.URL.createObjectURL(
          new Blob([Response], {
            type: "application/vnd.ms-excel",
          })
        );
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          "aging_summary_" + Math.round(+new Date() / 1000) + ".xlsx"
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch((err) => {
        console.log(err);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      });
  } catch (error) {
    console.log(error);
    ElMessage({
      type: "error",
      message: "Whoops! something went wrong downloading report",
    });
  } finally {
    isLoading.value = false;
  }
};
const downloadPdf = async () => {
  try {
    ElMessage({
      type: "info",
      message: "Your report will be downloading shortly. Please wait...",
    });
    await downloadAgingSummaryPdf(filter_params)
      .then((Response) => {
        let fileURL = window.URL.createObjectURL(
          new Blob([Response], {
            type: "application/pdf",
          })
        );
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          "aging_summary_" + Math.round(+new Date() / 1000) + ".pdf"
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch((err) => {
        console.log(err);

        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      });
  } catch (error) {
    console.log(error);
    ElMessage({
      type: "error",
      message: "Whoops! something went wrong downloading report",
    });
  } finally {
    isLoading.value = false;
  }
};
fetchData(undefined, 1);
</script>

<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="ni ni-calendar-grid-58 text-primary text-md opacity-10"></i>
        <h6>Aging Summary</h6>
      </div>
      <div class="cta">
        <el-button-group>
          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success" @click="downloadExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
        </el-button-group>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="tableData.length === 0">
        <empty-state-table />
      </div>

      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Patient Name
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                Phone
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                1-30 days
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                31-60 days
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                61-90 days
              </th>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
              >
                > 90 days
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in tableData" :key="item.id + index">
              <td class="ps-4 text-xs text-left">
                {{ item.first_name }} {{ item.middle_name }}
                {{ item.last_name }}
              </td>
              <td class="ps-4 text-xs text-left">{{ item.phone }}</td>
              <td class="ps-4 text-xs text-left">{{ item.cluster_a }}</td>
              <td class="ps-4 text-xs text-left">{{ item.cluster_b }}</td>
              <td class="ps-4 text-xs text-left">{{ item.cluster_c }}</td>
              <td class="ps-4 text-xs text-left">{{ item.cluster_d }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center mb-3"></div>
  </div>
</template>

<style scoped></style>
