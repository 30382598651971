import { api } from "../..";

export const fetchCategories = async (search, page) => {
  var url = "categories?orderBy=name&sortedBy=asc";
  if (search) url = url + "&searchFields=name:like;&search=" + search;
  if (page) url = url + "&page=" + page;
  url = url.replace("categories?&", "categories?");
   let response;
  response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchCategoriesTree = async () => {
  var url = "categories-tree";

   let response;
  response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createCategory = async (postBody) => {
   let response;
  response = await api
    .post("categories", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateCategory = async (body) => {
   let response;
  response = await api
    .patch("categories/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const removeCategory = async (id) => {
   let response;
  response = await api
    .delete(`categories/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchBrands = async (search, page) => {
  var url = "brands?orderBy=name&sortedBy=asc";
  if (search) url = url + "&searchFields=name:like;&search=" + search;
  if (page) url = url + "&page=" + page;
  url = url.replace("brands?&", "brands?");
   let response;
  response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createBrand = async (postBody) => {
   let response;
  response = await api
    .post("brands", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateBrand = async (body) => {
   let response;
  response = await api
    .patch("brands/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const removeBrand = async (id) => {
   let response;
  response = await api
    .delete(`brands/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchUnits = async (search, page) => {
  var url = "units?orderBy=name&sortedBy=asc";
  if (search) url = url + "&searchFields=name:like;&search=" + search;
  if (page) url = url + "&page=" + page;
  url = url.replace("units?&", "units?");
   let response;
  response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createUnit = async (postBody) => {
   let response;
  response = await api
    .post("units", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateUnit = async (body) => {
   let response;
  response = await api
    .patch("units/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const removeUnit = async (id) => {
   let response;
  response = await api
    .delete(`units/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchProducts = async (filter, page) => {
  var url = "products?orderBy=name&sortedBy=asc";
  if (filter.value !== undefined && filter.value.search_query)
    url =
      url +
      "&searchFields=name:like;code:like;category.name:like;brand.name:like;&search=" +
      filter.value.search_query;

  if (filter.value !== undefined && filter.value.service_id)
    url = url + "&service_id=" + filter.value.service_id;

  if (page) url = url + "&page=" + page;
  url = url.replace("products?&", "products?");

   let response;
  response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchProduct = async (product_id) => {
  var url = `products/${product_id}`;

   let response;
  response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createProduct = async (postBody) => {
   let response;
  response = await api
    .post("products", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateProduct = async (body) => {
   let response;
  response = await api
    .patch("products/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const removeProduct = async (id) => {
   let response;
  response = await api
    .delete(`products/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createStock = async (body) => {
   let response;
  response = await api
    .post("stock/", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchProductStock = async (id) => {
   let response;
  response = await api
    .get(`stock?searchFields=product_id:like;&search=${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const openStock = async (id) => {
   let response;
  response = await api
    .patch(`stock/open/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const closeStock = async (id) => {
   let response;
  response = await api
    .patch(`stock/close/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const removeStock = async (id) => {
   let response;
  response = await api
    .delete(`stock/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const resetProductStock = async (body) => {
   let response;
  response = await api
    .post("products/reset/stock", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
