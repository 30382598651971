import { api } from "../..";
const appointment_url = (root, filter, page) => {
  var url = root;
  if (filter.value !== undefined) {
    if (filter.value.search_query)
      url =
        url +
        "&searchFields=patient.first_name:like;patient.last_name:like;patient.middle_name:like;appointment_number:like;&search=" +
        filter.value.search_query;
    if (page) url = url + "&page=" + page;

    if (filter.value.date) url = url + "&appointment_date=" + filter.value.date;

    if (filter.value.date_range)
      url = url + "&date_range=" + filter.value.date_range;

    if (filter.value.patient_id)
      url = url + "&patient_id=" + filter.value.patient_id;

    if (filter.value.doctor_id)
      url = url + "&doctor_id=" + filter.value.doctor_id;

    if (filter.value.hospital_id)
      url = url + "&hospital_id=" + filter.value.hospital_id;

    if (filter.value.appointment_type)
      url = url + "&appointment_type=" + filter.value.appointment_type;

    if (filter.value.pending_invoice != undefined)
      url = url + "&pending_invoice=" + filter.value.pending_invoice;

    if (filter.value.appointment_status != undefined)
      url = url + "&appointment_status=" + filter.value.appointment_status;
  }
  url = url.replace("appointments?&", "appointments?");
  return url;
};
export const fetchAppointments = async (filter, page) => {
   let response;
  response = await api
    .get(appointment_url("appointments?orderBy=id&sortedBy=desc", filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchUnInvoicedAppointments = async (filter, page) => {
   let response;
  response = await api
    .get(appointment_url("appointments?orderBy=id&sortedBy=desc", filter, page))
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchAppointment = async (id) => {
   let response;
  response = await api
    .get(`appointments/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};
export const fetchRecords = async (filter, page) => {
  var url = "records?orderBy=id&sortedBy=desc";
  if (filter.value !== undefined) {
    if (page) url = url + "&page=" + page;
    if (filter.value.patient_id)
      url = url + "&patient_id=" + filter.value.patient_id;
  }
   let response;
  response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const downloadAppointmentExcel = async (filter, page) => {
   let response;
  response = await api
    .get(
      appointment_url(
        "appointments/download/excel?orderBy=id&sortedBy=desc",
        filter,
        page
      ),
      {
        responseType: "blob",
      }
    )
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};

export const downloadAppointmentPdf = async (filter, page) => {
   let response;
  response = await api
    .get(
      appointment_url(
        "appointments/download/pdf?orderBy=id&sortedBy=desc",
        filter,
        page
      ),
      {
        responseType: "blob",
      }
    )
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};
export const downloadPrescriptionPdf = async (appointment_id) => {
   let response;
  response = await api
    .get("appointment/print/prescription/" + appointment_id, {
      responseType: "blob",
    })
    .then((response) => response.data)
    .catch((e) => e.response.data);

  return response;
};

export const createAppointment = async (postBody) => {
   let response;
  response = await api
    .post("appointments", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateAppointment = async (body) => {
   let response;
  response = await api
    .patch("appointments/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const deleteAppointment = async (id) => {
   let response;
  response = await api
    .delete("appointments/" + id)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const checkInAppointment = async (id) => {
   let response;
  response = await api
    .post(`appointent/create-checkin/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const checkOutAppointment = async (id) => {
   let response;
  response = await api
    .post(`checkins/complete/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const saveRecord = async (id, postBody) => {
   let response;
  response = await api
    .post(`appointment/records/store/${id}`, postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchConsumables = async (appointment_id) => {
  var url = "appointment_consumables/" + appointment_id;

   let response;
  response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const saveConsumables = async (appointment_id, postBody) => {
   let response;
  response = await api
    .post("appointment_consumables/" + appointment_id, postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const updateLockState = async (appointment_id, postBody) => {
   let response;
  response = await api
    .post("appointment_consumables/lock/state/" + appointment_id, postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchConsumablesHistory = async (
  appointment_id,
  consumable_date
) => {
  var url =
    "appointment_consumables/history/" +
    appointment_id +
    "?consumable_date=" +
    consumable_date;

   let response;
  response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const deleteAppointments = async (body) => {
   let response;
  response = await api
    .post("appointments/delete", body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const createAppointmentStatus = async (postBody) => {
   let response;
  response = await api
    .post("appointment/status", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const createBulkAppointmentStatus = async (postBody) => {
   let response;
  response = await api
    .post("appointment/status/bulk/update", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const deleteUpload = async (id) => {
   let response;
  response = await api
    .delete(`uploads/${id}`)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
