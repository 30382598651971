import { api } from "../..";

export const login = async (postBody) => {
   let response;
  response = await api
    .post("auth/login", postBody)
    .then((response) => response)
    .catch((e) => e.response);
  return response;
};
export const logout = async (postBody) => {
   let response;
  response = await api
    .post("auth/logout", postBody)
    .then((response) => response)
    .catch((e) => e.response);
  return response;
};
export const signup = async (postBody) => {
   let response;
  response = await api
    .post("auth/register", postBody)
    .then((response) => response)
    .catch((e) => e.response);
  return response;
};
export const addclinic = async (postBody) => {
   let response;
  response = await api
    .post("auth/clinic/add", postBody)
    .then((response) => response)
    .catch((e) => e.response);
  return response;
};
export const sendLink = async (postBody) => {
   let response;
  response = await api
    .post("auth/forgot-password", postBody)
    .then((response) => response.data)
    .catch((e) => e.response);
  return response;
};
export const resetPassword = async (postBody, token) => {
   let response;
  response = await api
    .post(`auth/reset-password/${token}`, postBody)
    .then((response) => response.data)
    .catch((e) => e.response);
  return response;
};
export const verifyEmail = async (postBody, token) => {
   let response;
  response = await api
    .post(`auth/verify/${token}`, postBody)
    .then((response) => response.data)
    .catch((e) => e.response);
  return response;
};
export const changePassword = async (postBody) => {
   let response;
  response = await api
    .post("auth/update/password", postBody)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};
export const fetchRoles = async (filter) => {
  var url = "roles?orderBy=name&sortedBy=asc";

  if (filter !== undefined) {
    if (filter.value.organization_id)
      url = url + "&organization_id=" + filter.value.organization_id;
  }

   let response;
  response = await api
    .get(url)
    .then((response) => response.data)
    .catch((e) => e.response);
  return response;
};
export const fetchRole = async (id) => {
   let response;
  response = await api
    .get("roles/" + id)
    .then((response) => response.data)
    .catch((e) => e.response);
  return response;
};
export const removeRole = async (id) => {
   let response;
  response = await api
    .delete("roles/" + id)
    .then((response) => response.data)
    .catch((e) => e.response);
  return response;
};

export const createRole = async (postBody) => {
   let response;
  response = await api
    .post("roles", postBody)
    .then((response) => response.data)
    .catch((e) => e.response);
  return response;
};
export const updateRole = async (body) => {
   let response;
  response = await api
    .patch("roles/" + body.id, body)
    .then((response) => response.data)
    .catch((e) => e.response.data);
  return response;
};

export const fetchPermissions = async () => {
   let response;
  response = await api
    .get("permissions?orderBy=name&sortedBy=asc")
    .then((response) => response.data)
    .catch((e) => e.response);
  return response;
};
